<template>
  <div>
    <window-header></window-header>
    <nav-form>
      <template v-slot:after>
        <button
          v-if="mode == 'new' || mode == 'edit'"
          class="button button-fill"
          @click="importLines"
        >
          <fa-icon icon="file-import" />
          <span>Importar líneas</span>
        </button>
      </template>
      <template v-slot:actions>
        <li
          v-if="itemId"
          @click="exportarLineasCSV('pedido_id')"
        >
          Exportar líneas
        </li>
      </template>
    </nav-form>
    <section>
      <article style="height: 395px">
        <field
          name="ndoc"
          widget="char"
          searchable
          label="Nº Pedido"
          width="110px"
          style="top: 5px; left: 5px"
          inputStyle="text-align:right;"
        />
        <field
          name="fecha"
          type="date"
          label="Fecha"
          width="100px"
          style="top: 5px; left: 130px"
        />
        <field
          autofocus
          ref="proveedor_id"
          name="proveedor_id"
          widget="m2o"
          label="Proveedor"
          searchable
          width="320px"
          style="top: 5px; right: 55px"
          labelProp="nombre_comercial"
          imgProp="imagen.imagen"
          :filter="['activo','=',1]"
          :fields="[
            'nacionalidad',
            { name: 'divisa_id', fields: ['codigo', 'nombre', 'ratio'] },
            { name: 'impuesto_id', fields: ['nombre', 'valor'] },
            'descuento_especial',
            'financiacion',
            { name: 'comentarios', fields: ['descripcion', 'fecha', 'activo'] },
            {
              name: 'forma_pago_id',
              fields: [
                'codigo',
                'descripcion',
                'vencimientos',
                'periodicidad',
                'periodicidad_unidades',
              ],
            },
            'descuento_linea_unidad',
            'descuento_linea_peso',
            'merma',
            'merma_linea'
          ]"
        />
        <a
          href="#"
          v-tooltip="'Mostrar comentarios'"
          class="button button-plain"
          :class="{ disabled: !formData.proveedor_id }"
          style="position: absolute; top: 15px; right: 22px"
          @click="mostrarComentariosProveedor"
        >
          <fa-icon icon="sticky-note" />
        </a>
        <a
          href="#"
          v-tooltip="'Abrir saldos'"
          class="button button-plain"
          :class="{ disabled: !formData.proveedor_id }"
          style="position: absolute; top: 15px; right: 5px"
          @click="abrirSaldos"
        >
          <fa-icon icon="dollar-sign" />
        </a>
        <r-tabs
          ref="main-tabs"
          style="top: 40px; height: 353px"
          :wt="formData.wt"
          :buttons="[
            'Cabecera',
            'Otros costes',
            'Líneas',
            'Pie',
            'Taquería',
            'Estadísticas',
          ]"
          :classButton="{
            disabled: !formData.proveedor_id && mode != 'search',
          }"
          @active="onActiveTab"
        >
          <div class="tab">
            <field
              name="npedido"
              widget="char"
              label="S. Nº de Pedido"
              width="150px"
              style="top: 10px; left: 10px"
            />
            <field
              name="fpedido"
              type="date"
              label="F. Pedido"
              width="100px"
              style="top: 10px; left: 178px"
            />
            <fieldset style="
                position: relative;
                left: 10px;
                top: 50px;
                width: 230px;
                height: 59px;
              ">
              <legend>Importación</legend>
              <field
                name="importacion"
                widget="radio"
                :readonly="true"
                default="union_europea"
                :options="{
                  Nacional: 'nacional',
                  'Extracom.': 'extracomunitario',
                  UE: 'union_europea',
                }"
                inline-label
                style="top: 6px; left: 20px"
              />
            </fieldset>
            <field
              ref="divisa_id"
              name="divisa_id"
              widget="m2o"
              label="Divisa"
              width="120px"
              style="top: 115px; left: 10px"
              :fields="['ratio']"
              external-window="moneda"
            />

            <field
              name="cambio"
              widget="float"
              type="number"
              dec="6"
              label="Cambio"
              width="80px"
              style="top: 115px; left: 160px"
              :class="{wrong: formData.divisa_id && !parseFloat(formData.cambio)}"
            />
            <fieldset style="
                position: relative;
                left: 250px;
                top: -9px;
                width: 380px;
                height: 59px;
              ">
              <legend>Metal</legend>
              <field
                ref="tipo_metal"
                name="tipo_metal"
                widget="radio"
                default="no"
                :options="{ No: 'no', Cuenta: 'cuenta', Factura: 'factura' }"
                style="top: 6px; left: 15px"
              />
              <field
                ref="metal_id"
                name="metal_id"
                widget="m2o"
                labelProp="descripcion"
                :readonly="
                  formData.tipo_metal != 'cuenta' &&
                  formData.tipo_metal != 'factura'
                "
                width="170px"
                style="top: 7px; left: 192px"
                :fields="['cotizacion', 'factor']"
              />
            </fieldset>

            <field
              name="prmetalp"
              widget="float"
              type="number"
              dec="2"
              inputStyle="text-align:center;"
              label="Pr. Metal/P"
              width="70px"
              style="top: 115px; left: 275px"
              :readonly="
                formData.tipo_metal != 'cuenta' &&
                formData.tipo_metal != 'factura'
              "
            />
            <field
              name="factor"
              widget="float"
              inputStyle="text-align:right;font-size:10px;"
              type="number"
              dec="4"
              inline-label
              label="Factor"
              width="40px"
              style="top: 153px; left: 275px"
              :readonly="true"
            />
            <field
              name="merma"
              widget="float"
              type="number"
              dec="2"
              inputStyle="text-align:center;"
              label="Merma (%/m)"
              width="70px"
              style="top: 115px; left: 350px"
              :readonly="
                formData.tipo_metal != 'cuenta' &&
                formData.tipo_metal != 'factura'
              "
            />
            <field
              name="contraste"
              widget="float"
              type="number"
              dec="2"
              inputStyle="text-align:center;"
              label="Contraste/P"
              width="70px"
              style="top: 115px; left: 425px"
            />
            <field
              name="ocostes"
              widget="float"
              type="number"
              dec="2"
              inputStyle="text-align:center;"
              label="O. Costes/P"
              width="70px"
              style="top: 115px; left: 500px"
            />
            <field
              name="mermaporlinea"
              widget="checkbox"
              inline-label
              label="Merma por Línea"
              style="top: 153px; left: 526px"
            />
            <field
              name="adjuntos"
              :max="6"
              widget="files"
              label="Adjuntos"
              style="top: 168px; left: 11px"
            />
            <field
              name="observaciones"
              widget="text"
              label="Observaciones"
              width="429px"
              height="40px"
              style="top: 168px; right: 11px"
            />

            <field
              name="delegacion_id"
              widget="m2o"
              label="Deleg."
              width="277px"
              style="top: 243px; left: 10px"
              :readonly="mode == 'edit'"
              inputStyle="font-weight:bold;text-align:center;"
              searchable
            />
            <field
              name="tipo_id"
              widget="m2o"
              label="Tipo"
              width="290px"
              style="top: 243px; left: 295px"
              :readonly="mode == 'edit'"
              searchable
            />
          </div>
          <div class="tab">
            <!--Otros costes -->
            <field
              ref="otroscostes"
              name="otroscostes"
              widget="handsontable"
              :height="225"
              :minRows="9"
              style="top: 5px; left: 4px; width: 648px"
              :columns="[
                {
                  name: 'c',
                  header: 'Coste',
                  type: 'm2o',
                  label: 'descripcion',
                  primary: 'codigo',
                  showCode: true,
                  fields: ['descripcion', 'precio_predeterminado'],
                },
                {
                  name: 'impdivisa',
                  header: 'Imp. Divisa',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  help: 'Importe en la divisa del pedido',
                },
                {
                  name: 'impdivisabase',
                  header: 'Imp. Div. Base',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  help: 'Importe en la divisa base',
                },
                {
                  name: 'p',
                  header: 'P',
                  type: 'checkbox',
                  help: 'Incluir el importe en el pie del pedido',
                },
                {
                  name: 'l',
                  header: 'L',
                  type: 'checkbox',
                  help: 'Repercutir el importe en el coste de los artículos',
                },
              ]"
            />
          </div>
          <div class="tab">
            <field
              ref="lineas"
              name="lineas"
              class="lineas"
              :htSettings="{ rowHeights: 15 }"
              widget="handsontable"
              :height="303"
              :minRows="18"
              style="top: 5px; left: 4px; width: 648px"
              customprop="lineas_customprop"
              :fields="['mpl']"
              :columns="[
                {
                  name: 'ean13',
                  header: '|||||||||',
                  help: 'Código de barras del artículo',
                },
                {
                  name: 'modelo_id',
                  header: 'Modelo',
                  help: 'Modelo del artículo',
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'codigo',
                },
                {
                  name: 'articulo_id',
                  header: 'Artículo',
                  help: 'Código del artículo',
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'codigo',
                  noOpen: true,
                  fields: [
                    { name: 'thumbnail', fields: ['imagen'] },
                    { name: 'familia_id', fields: ['codigo', 'nombre'] },
                  ],
                },
                {
                  name: 'descripcion',
                  help: 'Descripción del artículo',
                  header: 'Descripción',
                },
                {
                  name: 'refproveedor',
                  header: 'R. Proveedor',
                  help: 'Ref. del proveedor del artículo',
                },
                { name: 'nota', header: 'Nota' },
                //{name:'familia_id',header:'Familia', type:'m2o', primary:'codigo', label:'codigo', readOnly:true},
                //{name:'tt',header:'T', type:'select', options:['A','R']},
                {
                  name: 'almacen_id',
                  header: 'Almacén',
                  help: 'Almacén',
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'codigo',
                  readOnly: true,
                },
                {
                  name: 'unidades',
                  header: 'Unid.',
                  help: 'Unidades',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'etiqueta',
                  help: 'Etiquetas',
                  header: 'Etiq.',
                  type: 'numeric',
                },
                {
                  name: 'peso',
                  header: 'Peso',
                  help: 'Peso',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.000' },
                },
                {
                  name: 'c',
                  header: 'C',
                  help: '<U> Compra por Unidad <P> Compra por Peso',
                  type: 'select',
                  options: ['U', 'P'],
                },
                {
                  name: 'm',
                  header: 'M',
                  help: 'Compra con Metal incluido',
                  type: 'checkbox',
                },
                {
                  name: 'r',
                  header: 'R',
                  help: 'Compra con Rec. equivalencia',
                  type: 'checkbox',
                },

                {
                  name: 'precio',
                  header: 'Precio',
                  help: 'Precio Compra',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'descuentos',
                  header: 'Dtos.',
                  help: 'Dto.',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  sufix: '%',
                },
                {
                  name: 'totallinea',
                  header: 'Total Línea',
                  help: 'Total Línea',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'm2',
                  header: 'M',
                  type: 'checkbox',
                  help: 'Marcar si se acumula el Peso del Metal',
                },
                {
                  name: 'c2',
                  header: 'C',
                  type: 'checkbox',
                  help: 'Marcar si se aplica Contraste por Peso',
                },
                {
                  name: 'o',
                  header: 'O',
                  type: 'checkbox',
                  help: 'Marcar si se aplica otros costes por Peso',
                },
                {
                  name: 'precio_coste',
                  header: 'P. Coste',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  help: 'Precio de Coste del Articulo',
                },
                {
                  name: 'coste_fijo',
                  header: 'Coste Fijo',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  help: 'Coste fijo por Unidad que se incrementará al coste de la pieza',
                },
                {
                  name: 'taqueria_ids',
                  header: 'Taquería',
                  help: 'Cód. Taqueria',
                  type: 'm2m',
                  primary: 'codigo',
                  image: 'thumbnail.imagen',
                  label: 'codigo',
                  fields: ['precio_coste'],
                  filter: () => ['seccion_id', '=', 'PCK'],
                },
                {
                  name: 'taqueria_precio',
                  header: 'Taq.(€)',
                  help: 'Coste total de taqueria',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'incluir_taqueria',
                  header: 'P',
                  help: 'Marcar si se incrementará al coste de la pieza el precio de la taquería',
                  type: 'checkbox',
                  help: 'Incluir Taqueria',
                },
                {
                  name: 'otros_costes',
                  header: 'O. Costes',
                  help: 'Otros Costes repercutidos por línea. Se incrementará al coste de la pieza',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'v',
                  header: 'V',
                  help: '<U> Venta por Unidad <P> Venta por Peso',
                  type: 'select',
                  options: ['U', 'P'],
                  help: '(U) Venta por Unidades, (P) Venta por Peso',
                },
                {
                  name: 'm3',
                  header: 'M',
                  help: 'Venta con metal incluido',
                  type: 'checkbox',
                  help: 'Vende con Metal Incluido',
                },
                {
                  name: 'otros_costes',
                  header: 'O. Costes',
                  help: 'Otros Costes repercutidos por línea. Se incrementará al coste de la pieza',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'unidades_pendientes',
                  header: 'Uds.Pend.',
                  help: 'Unidades pendientes de albaranar/facturar',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'peso_pendiente',
                  header: 'Peso.Pend.',
                  help: 'Peso pendiente de albaranar/facturar',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'lineas_albaran_generadas',
                  header: 'Albaranes',
                  help: 'Albaranes generados',
                  type: 'm2m', //fix for fields to work
                  primary: 'id',
                  fields: [{ name: 'albaran_id', fields: ['ndoc'] }],
                  readOnly: true,
                  renderer: rendererLineasAlbaranGeneradas,
                },
                {
                  name: 'lineas_factura_generadas',
                  header: 'Facturas',
                  help: 'Facturas generadas',
                  type: 'm2m', //fix for fields to work
                  primary: 'id',
                  fields: [{ name: 'factura_id', fields: ['ndoc'] }],
                  readOnly: true,
                  renderer: rendererLineasFacturaGeneradas,
                },
              ]"
            />
          </div>
          <div class="tab">
            <field
              name="total_unidades"
              widget="float"
              type="number"
              dec="2"
              label="Total Unidades"
              width="70px"
              style="top: 5px; right: 328px"
              :readonly="true"
            />
            <field
              name="total_peso"
              widget="float"
              type="number"
              dec="3"
              label="Total Peso"
              width="70px"
              style="top: 5px; right: 250px"
              :readonly="true"
              suffix=" g"
            />
            <field
              name="total_articulos"
              widget="float"
              type="number"
              dec="2"
              label="Total Artículos"
              inline-label
              width="70px"
              style="top: 10px; right: 12px"
              :readonly="true"
            />

            <field
              name="dto_especial_porcentaje"
              widget="float"
              type="number"
              dec="2"
              label="Dto. Especial"
              inline-label
              width="70px"
              style="top: 30px; right: 90px"
              placeholder="%"
              suffix=" %"
            />
            <field
              name="dto_especial"
              widget="float"
              type="number"
              dec="2"
              width="70px"
              style="top: 30px; right: 12px"
            />

            <field
              name="g18k"
              widget="float"
              type="number"
              dec="3"
              :label="
                (formData.metal_id && formData.metal_id.descripcion) ||
                'G. Aleación'
              "
              width="70px"
              style="top: 45px; right: 328px"
              suffix=" g"
            />
            <field
              name="gmerma"
              widget="float"
              type="number"
              dec="3"
              label="G. Merma"
              width="70px"
              style="top: 45px; right: 250px"
              suffix=" g"
            />

            <field
              name="g24k_porcentaje"
              widget="float"
              type="number"
              dec="3"
              :label="
                (formData.metal_id && formData.metal_id.descripcion) ||
                'G. Aleación'
              "
              inline-label
              width="70px"
              style="top: 50px; right: 90px"
              :readonly="true"
              suffix=" g"
            />
            <field
              name="g24k"
              widget="float"
              type="number"
              dec="2"
              width="70px"
              style="top: 50px; right: 12px"
              :readonly="true"
            />
            <field
              name="gcontraste_porcentaje"
              type="number"
              dec="3"
              widget="float"
              label="G. Contraste"
              inline-label
              width="70px"
              style="top: 70px; right: 90px"
              suffix=" g"
            />
            <field
              name="gcontraste"
              widget="float"
              type="number"
              dec="2"
              width="70px"
              style="top: 70px; right: 12px"
              :readonly="true"
            />
            <field
              name="gotroscostes_porcentaje"
              widget="float"
              type="number"
              dec="3"
              label="G. Otros Costes"
              inline-label
              width="70px"
              style="top: 90px; right: 90px"
              suffix=" g"
            />
            <field
              name="gotroscostes"
              widget="float"
              type="number"
              dec="2"
              width="70px"
              style="top: 90px; right: 12px"
              :readonly="true"
            />
            <!--<field
              name="uotroscostes_porcentaje"
              widget="float"
              type="number"
              dec="3"
              label="U. Otros Costes"
              inline-label
              width="80px"
              style="top: 90px; right: 100px"
              placeholder="%"
            />-->
            <field
              name="costes_adicionales"
              widget="float"
              label="Costes adicionales"
              inline-label
              width="70px"
              style="top: 110px; right: 12px"
              type="number"
              dec="2"
              readonly
            />
            <field
              name="financiacion_porcentaje"
              widget="float"
              type="number"
              dec="2"
              label="Financiación"
              inline-label
              width="70px"
              style="top: 130px; right: 90px"
              placeholder="%"
              suffix=" %"
            />
            <field
              name="financiacion"
              widget="float"
              type="number"
              dec="2"
              inline-label
              width="70px"
              style="top: 130px; right: 12px"
            />

            <field
              name="comprobante_factura_emitido"
              widget="checkbox"
              label="Comprobante de Factura Emitido"
              reverse
              inline-label
              style="top: 10px; left: 14px"
            />
            <field
              name="etiquetas_facturas_emitidas"
              widget="checkbox"
              label="Etiquetas de Facturas Emitidas"
              reverse
              inline-label
              style="top: 30px; left: 14px"
            />

            <div style="position: absolute; bottom: 0px">
              <table class="tabla-pie">
                <tr>
                  <td></td>
                  <th>B. Imponible</th>
                  <th>
                    {{ formData.tipo_impuesto }}
                    ({{ $utils.misc.formatNumber(formData.ivap) }}%)
                  </th>
                  <th>
                    Recargo ({{ $utils.misc.formatNumber(formData.rep) }}%)
                  </th>
                  <th>
                    <b>Totales</b>
                  </th>
                </tr>
                <tr>
                  <td style="width: 100px">Base 1 <small>(IMP.)</small></td>
                  <td>
                    <field
                      name="baseimponible_sre"
                      widget="float"
                      type="number"
                      width="100%"
                      style="position: relative"
                      :readonly="true"
                    />
                  </td>
                  <td>
                    <field
                      name="iva_sre"
                      widget="float"
                      type="number"
                      width="100%"
                      style="position: relative"
                      :readonly="true"
                    />
                  </td>
                  <td>
                    <field
                      name="recargo_equivalencia_sre"
                      widget="float"
                      type="number"
                      width="100%"
                      style="position: relative"
                      :readonly="true"
                    />
                  </td>
                  <td>
                    <field
                      name="total2"
                      widget="float"
                      type="number"
                      width="100%"
                      style="position: relative"
                      :readonly="true"
                    />
                  </td>
                </tr>
                <tr>
                  <td style="width: 115px">Base 2 <small>(IMP.+RE)</small></td>
                  <td>
                    <field
                      name="baseimponible_re"
                      widget="float"
                      type="number"
                      width="100%"
                      style="position: relative"
                      :readonly="true"
                    />
                  </td>
                  <td>
                    <field
                      name="iva_re"
                      widget="float"
                      type="number"
                      width="100%"
                      style="position: relative"
                      :readonly="true"
                    />
                  </td>
                  <td>
                    <field
                      name="recargo_equivalencia_re"
                      widget="float"
                      type="number"
                      width="100%"
                      style="position: relative"
                      :readonly="true"
                    />
                  </td>
                  <td>
                    <field
                      name="total1"
                      widget="float"
                      type="number"
                      width="100%"
                      style="position: relative"
                      :readonly="true"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Base Metal
                    <field
                      name="base_metal_check"
                      widget="checkbox"
                      style="display: inline-flex"
                    />
                  </td>
                  <td>
                    <field
                      name="baseimponible_metal"
                      widget="float"
                      type="number"
                      width="100%"
                      style="position: relative"
                      :readonly="true"
                    />
                  </td>
                  <td>
                    <field
                      name="iva_metal"
                      widget="float"
                      type="number"
                      width="100%"
                      style="position: relative"
                      :readonly="true"
                    />
                  </td>
                  <td>
                    <field
                      name="recargo_equivalencia_metal"
                      widget="float"
                      type="number"
                      width="100%"
                      style="position: relative"
                      :readonly="true"
                    />
                  </td>
                  <td>
                    <field
                      name="total_metal"
                      widget="float"
                      type="number"
                      width="100%"
                      style="position: relative"
                      :readonly="true"
                    />
                  </td>
                </tr>
                <tr>
                  <th
                    colspan="4"
                    class="total-cell"
                  >Rec. Financiero</th>
                  <th>
                    <field
                      name="financiacion"
                      widget="float"
                      type="number"
                      width="100%"
                      style="position: relative"
                      :readonly="true"
                    />
                  </th>
                </tr>
                <tr>
                  <th
                    colspan="4"
                    class="total-cell"
                  >Total</th>
                  <th>
                    <field
                      name="total"
                      widget="float"
                      type="number"
                      width="100%"
                      style="position: relative"
                      :readonly="true"
                      inputStyle="font-weight:bold; background: rgba(0, 140, 186, 0.3)!important;"
                    />
                  </th>
                </tr>
              </table>
            </div>
          </div>
          <div class="tab">
            <field
              ref="taqueria_lineas"
              name="taqueria_lineas"
              widget="handsontable"
              :height="278"
              :minRows="12"
              :readonly="true"
              style="top: 10px; left: 10px; width: 637px"
              customprop="taquerialineas_customprop"
              :columns="[
                {
                  name: 'taqueria_id',
                  header: 'Artículo',
                  type: 'm2o',
                  model: 'articulo',
                  primary: 'codigo',
                  showCode: true,
                  image: 'thumbnail.imagen',
                  label: 'nombre',
                  filter: (value, row, prop) => ['seccion_id', '=', 'PCK']
                },
                {
                  name: 'unidades_teoricas',
                  header: 'Unidades',
                  type: 'numeric',
                  format: '0',
                  readOnly: true,
                },
              ]"
            />
          </div>
          <div class="tab">
            <field
              name="total_unidades"
              widget="float"
              type="number"
              dec="2"
              label="Total Unidades"
              inline-label
              width="80px"
              style="top: 10px; left: 14px"
              :readonly="true"
            />
            <field
              name="total_peso"
              widget="float"
              type="number"
              dec="3"
              label="Total Peso"
              inline-label
              width="80px"
              style="top: 10px; left: 195px"
              :readonly="true"
            />
            <field
              name="total"
              widget="float"
              type="number"
              dec="2"
              label="Total Importe"
              inline-label
              width="80px"
              style="top: 10px; left: 350px"
              :readonly="true"
            />
            <div
              class="item-input inline-label field"
              style="top: 10px; left: 520px"
            >
              <div class="item-inner">
                <label class="item-title item-label">€/pieza</label>
                <div class="item-input-wrap">
                  <input
                    name="precio_medio_pieza"
                    type="text"
                    style="width: 80px; text-align: right"
                    :value="precioMedioPieza"
                    readonly
                  />
                </div>
              </div>
            </div>
            <field
              ref="estadisticas_familias"
              name="estadisticas_familias"
              widget="handsontable"
              :height="247"
              :minRows="10"
              style="top: 45px; left: 10px; width: 637px"
              :readonly="true"
              :columns="[
                {
                  name: 'familia_id',
                  header: 'Familia',
                  type: 'm2o',
                  label: 'nombre',
                  primary: 'codigo',
                  showCode: true,
                  model: 'familia',
                },
                {
                  name: 'unidades',
                  header: 'Unidades',
                  type: 'numeric',
                  format: '0',
                },
                {
                  name: 'precio_medio',
                  header: 'Precio medio',
                  type: 'numeric',
                  format: '0,0.00',
                },
              ]"
            />
          </div>
        </r-tabs>
        <!-- tabs -->
      </article>
    </section>
    <v-collapse-wrapper
      ref="main-collapse"
      :active="true"
    >
      <div
        class="header"
        v-collapse-toggle
      >
        Listado
        <div class="item-after">
          <field
            widget="select"
            name="anio"
            style="position: relative; width: 100px; margin-right: 24px;"
            width="100"
            @click="$event.stopPropagation()"
            :options="years.reduce((a, v) => ({ ...a, [v]: v}), {})"
            searchable
            :readonly="mode != 'search'"
            placeholder="Todos los años"
            v-if="years.length"
          />
          {{ count }}
        </div>
      </div>
      <div
        class="my-content"
        v-collapse-content
      >
        <hot-table
          ref="hotTableComponent"
          :settings="htSettings"
        ></hot-table>
      </div>
    </v-collapse-wrapper>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";
import CompraMixin from "./../components/CompraMixin.js";
import FacturaMixin from "./../components/FacturaMixin.vue";

export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin, FacturaMixin, CompraMixin],
  data: function () {
    var self = this;
    return {
      title: "Pedidos de proveedor",
      dbAdapter: "pedido_proveedor",
      primary: "id",
      defaultDataSearch: {
        wt: 0,
        anio: new Date().getFullYear(),
        //tipo_id: self.app.config.tipo_documento_defecto
      },
      defaultData: {
        wt: 0,
        fecha: new Date().yyyymmdd(),
        fpedido: new Date().yyyymmdd(),
        tipo_metal: "no",
        delegacion_id: self.app.config.delegacion_defecto || null,
        merma: 0,
        prmetalp: 0,
        contraste: 0,
        ocostes: 0,
        tipo_id: self.app.config.tipo_documento_defecto,
      },
      /*sequence: {
        field: "ndoc",
        name: function () {
          return self.formData.tipo_id
            ? "pedido_compra_" +
                (self.formData.wt ? "1" : "0") +
                "_" +
                self.formData.tipo_id.codigo
            : null;
        },
        params: ["tipo_id.codigo", "wt"],
      },*/
    };
  },
  mounted() {
    var self = this;
    setTimeout(function () {
      self.actualizarTodo(true);
    }, 1000);
  },
  methods: {
    onActiveTab(i) {
      let self = this;
      switch (i) {
        case 3: //pie
        case 4: //vtos
        case 5: //taqueria
        case 6: //estadisticas
          self.actualizar_total_unidades();
          self.actualizar_total_peso();
          self.actualizar_total_articulos();
          self.actualizar_g18k();
          self.actualizar_gmerma();
          self.actualizar_gcontraste_porcentaje();
          self.actualizar_gotroscostes_porcentaje();
          self.actualizar_costes_adicionales();
          self.actualizar_taqueria();
          self.actualizar_estadisticas_familias();
          break;
      }
    },
  },
};
</script>